export const initialAuthState = {
  authReducer: {
    isAuthenticated: false,
    email: '',
    error: false,
    messageAtResetPassword: false,
    messageAtForgotPassword: false,
    username: '',
    id: null,
    showChangePasswordModal: false
  }
};
