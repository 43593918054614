import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../src/theme";
import { StateProvider } from "../context/store";

import "../styles/globals.css";

import NProgress from "nprogress"
import { useRouter } from "next/router";

const MyApp = (props) => {
    const { Component, pageProps } = props;

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document?.querySelector("#jss-server-side");
        if (jssStyles) {
            jssStyles?.parentElement?.removeChild(jssStyles);
        }

    }, []);

    const router = useRouter();

    const [loadingRoute, setLoadingRoute] = useState({isLoading: false, route: ''});

    useEffect(() => {
        let routeChangeStart = (url) => {
            setLoadingRoute({isLoading: true, route: url});
            NProgress.start();
        }
        let routeChangeComplete = (url) => {
            setLoadingRoute({ isLoading: false, route: url });
            NProgress.done();
            // trackPageVisits(url);
        }

        router.events.on("routeChangeStart", routeChangeStart);
        router.events.on("routeChangeComplete", routeChangeComplete);
        router.events.on("routeChangeError", routeChangeComplete);
        return () => {
            router.events.off("routeChangeStart", routeChangeStart);
            router.events.off("routeChangeComplete", routeChangeComplete);
            router.events.off("routeChangeError", routeChangeComplete);
        };
    }, [router]);

    return (
        <React.Fragment>
            <Head>
                <title>Vinco</title>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
                <link rel="icon" href="/vinco_isotipo.png" />
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/19522760.js"></script>
            </Head>
            <StateProvider>
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <Component {...pageProps} loadingRoute={loadingRoute} />
                </ThemeProvider>
            </StateProvider>
        </React.Fragment>
    );
};

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};

export default MyApp;
