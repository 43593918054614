export const initialStateCourses = {
    coursesReducer: {
        redirectFromIndex: false,
        areMobileFiltersOpen: false,
        coursesInStore: [],
        savedCourses: [],
        recommendedCourses: [],
        startedSaving: false,
        filteredSavedCourses: [],
        courseTypes: [],
        totalCourses: 0,
        searchBar: {
            loading: false,
            inputValue: '',
            query: '',
            searchBy: 'course_name',
            foundCourses: [],
            foundCoursesCount: 0,
            sortBy: 10,
            numberOfPages: 1,
            page: 1
        },
        favoriteSearchBar: {
            loading: false,
            inputValue: '',
            query: '',
            searchBy: 'course_name',
            sortBy: 10,
            numberOfPages: 1,
            page: 1
        },
        pagination: {
            sortBy: 10,
            numberOfPages: 0,
            query: ""
        },
        filteredCourses: [],
        filterRules: {
            coursesTypes: [],
            universities: []
        },
        filterByDate: {
            registrationDate: '',
            registrationDateGte: '',
            registrationDateLte: '',
        },
        filterByCourseStart: {
            startDate: '',
            startDateGte: '',
            startDateLte: '',
        },
        filterByRecurringDate: '',
        universities: [],
        selectedCourse: {},
        sortBy: 10,
        foundCourses: [],
        foundCoursesCount: 0,
        coursesLoading: false,
        activePage: 1,
        totalPages: 1,
        subjectAreaTags: [],
        filterBySubjectAreaTags: [],
    }
}