export const OPEN_SNACKBAR = "app/OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "app/CLOSE_SNACKBAR";
export const IS_LOADING = "app/IS_LOADING";

export const START_LOGIN_PROGRESS = "app/START_LOGIN_PROGRESS";
export const FINISH_LOGIN_PROGRESS = "app/FINISH_LOGIN_PROGRESS";
export const RESET_LOGIN_PROGRESS = "app/RESET_LOGIN_PROGRESS";
export const IS_LOGIN_SUCCESSFULL = "app/IS_LOGIN_SUCCESSFULL";
export const GET_DATA_CURRENCY = "app/GET_DATA_CURRENCY";
export const RESET_LOGIN_STATE = "app/RESET_LOGIN_STATE";

export const TOGGLE_MAIN_MENU = "app/TOGGLE_MAIN_MENU";