import {
  CLOSE_SNACKBAR,
  IS_LOADING,
  OPEN_SNACKBAR,
  START_LOGIN_PROGRESS,
  FINISH_LOGIN_PROGRESS,
  RESET_LOGIN_PROGRESS,
  IS_LOGIN_SUCCESSFULL,
  GET_DATA_CURRENCY,
  RESET_LOGIN_STATE,
  TOGGLE_MAIN_MENU
} from '../action_types';

export const applicationReducer = (state, action) => {
  switch (action.type) {
    case RESET_LOGIN_STATE:
      return {
        ...state,
        isLoginSuccessfull: false,
        loginProgress: 0,
      };
    case IS_LOGIN_SUCCESSFULL: 
      return {
        ...state, 
        isLoginSuccessfull: true
      };
    case START_LOGIN_PROGRESS: 
      return {
        ...state, 
        loginProgress: action.payload
      };
    case FINISH_LOGIN_PROGRESS:
      return {
        ...state,
        loginProgress: 100,
      };
    case RESET_LOGIN_PROGRESS:
      return {
        ...state,
        loginProgress: 0,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state, snackbarNotification: {
          isOpen: false
        }
      };
    case IS_LOADING:
      return { ...state, loading: action.payload.loading };
    case OPEN_SNACKBAR:
      const newSnackbarNotification = {
        isOpen: true,
        email:action?.payload?.email,
        message: action?.payload?.message,
        severity: action?.payload?.severity,
        anchorOrigin: action?.payload?.anchorOrigin ? { ...anchorOrigin } : { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: action?.payload?.autoHideDuration,
      };
      return { ...state, snackbarNotification: { ...newSnackbarNotification } };
    case TOGGLE_MAIN_MENU: 
      return {
        ...state,
        isMainMenuOpen: !state.isMainMenuOpen
      };
    case GET_DATA_CURRENCY: 
      return {
        ...state, 
        currency: action.payload
      };
    default:
      return state;
  };
}; 