export const initialApplicationState = {
  applicationReducer: {
    loading: false,
    loginProgress: 0,
    isLoginSuccessfull: false,
    currency: '',
    snackbarNotification: {
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: null,
      isOpen: false,
      message: '¡Bienvenido!',
      severity: 'success',
    },
    isMainMenuOpen: true,
  }
};
