import {
  SET_SIGNUP_FIELD,
  SET_ERROR_AT_SIGNUP_FIELD,
  SET_COMPANIES_DROPDOWN,
  SET_SIGNUP_STEPS,
  SIGNUP_NEXT_STEP,
  SIGNUP_BACK_STEP,
  SET_STEP_FORM_VALIDATION,
  SET_USER_TYPES
} from '../action_types';

export const signupReducer = (state, action) => {
  switch (action.type) {
    case SET_COMPANIES_DROPDOWN:
      const allCompanies = action?.payload
      const companiesIncludedList = allCompanies.filter((company) => {
        if (company?.config?.showOnSignUp) return company;
      })
      return {
        ...state,
        companiesAtDropdown: [...companiesIncludedList]
      }
    case SET_USER_TYPES:
      return {
        ...state,
        userTypes: action.payload
      }
    case SET_SIGNUP_FIELD:
      return {
        ...state,
        formInfo: {
          ...state.formInfo,
          [action.payload.id]: action.payload.value,
        }
      };
    case SET_ERROR_AT_SIGNUP_FIELD:
      return {
        ...state,
        errorsInForm: {
          ...state.errorsInForm,
          [action.payload.id]: action.payload.value,
        }
      };
    case SET_SIGNUP_STEPS:
      return {
        ...state,
        steps: [action.payload.steps],
      };
    case SIGNUP_NEXT_STEP:
    case SIGNUP_BACK_STEP:
      return {
        ...state,
        activeStep: action.payload.step
      };
    case SET_STEP_FORM_VALIDATION:
      return {
        ...state,
        isFormValid: {
          ...state.isFormValid,
          [action.payload.name]: action.payload.value
        }
      };
    default:
      return state;
  };
}; 