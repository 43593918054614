export const essentialDataInitialState = {
  essentialDataReducer: {
    isSurveyLoading: false,
    willAnswerLater: false,
    questions: [],
    totalQuestions: 0,
    activeQuestion: 0,
    answers: {}
  }
};

