import {
  ANSWER_SURVEY_QUESTION,
  CHANGE_SURVEY_QUESTION,
  IS_SURVEY_LOADING,
  SET_SURVEY_DATA,
  WILL_ANSWER_ESSENTIAL_DATA_LATER,
} from '../action_types';

export const essentialDataReducer = (state, action) => {
  switch (action.type) {
    case ANSWER_SURVEY_QUESTION:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.position]: {
            id: action.payload.id,
            question_text: action.payload.question_text,
            answer: action.payload.answer,
            position: action.payload.position, // Optional but helpful
          }
        }
      };

    case CHANGE_SURVEY_QUESTION:
      return {
        ...state,
        activeQuestion: state.activeQuestion + action.payload
      };

    case IS_SURVEY_LOADING:
      return {
        ...state,
        isSurveyLoading: action.payload
      };

    case WILL_ANSWER_ESSENTIAL_DATA_LATER:
      return {
        ...state,
        willAnswerLater: true
      };

    case SET_SURVEY_DATA:
      return {
        ...state,
        questions: action.payload.questions,
        totalQuestions: action.payload.totalQuestions
      };

    default:
      return state;
  }
};
