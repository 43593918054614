export const theme = {
    primary: "#009888",
    secondary: "#4A4A7C",
    tertiary: "#566573",
    processing: "#FFc107",
    error: "#FF1744",
    white: "#FFFFFF",
    green: "#3FB459",
    red: "#DB1919",
    gray: "#757575",
    grayLight: "#EFF0EF",
    black: "#1D262D",
    purpleButton: "#4A4A7C",
    lighterPurple: "#2c227c",
    extra:'#EF8A7F'
};

export const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '500px',
    tablet: '768px',
    tabletL: '880px',
    laptop: '1024px',
    laptopMM: '1124px',
    laptopM: '1280px', 
    laptopL: '1440px',
    desktop: '2000px',
    desktopL: '2560px'
}

export const sizeInNumber = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2000,
    desktopL: 2560
}

export const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    tabletL: `(max-width: ${size.tabletL})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopMM: `(max-width: ${size.laptopMM})`,
    laptopM: `(max-width: ${size.laptopM})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktopL})`
};