import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { theme as themeConstants } from '../styles/theme-constants';

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: `${themeConstants.purpleButton}`,
        },
        secondary: {
            main: '#009888',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: [
            'Gilroy',
            'sans-serif',
        ].join(','),
    }
});

export default theme;
