export const TOGGLE_MOBILE_FILTERS = "courses/TOGGLE_MOBILE_FILTERS";
export const SET_COURSES = "SET_COURSES";
export const SET_SAVED_COURSES = "courses/SET_SAVED_COURSES";
export const SET_RECOMMENDED_COURSES = "courses/SET_RECOMMENDED_COURSES";
export const ADD_SAVED_COURSE = "courses/ADD_SAVED_COURSE";
export const DELETE_SAVED_COURSE = "courses/DELETE_SAVED_COURSE";
export const SET_FILTERED_COURSES = "SET_FILTERED_COURSES";
export const SET_COURSE_TYPES = "SET_COURSE_TYPES";
export const SET_FILTER_CHECKBOX = "SET_FILTER_CHECKBOX";
export const DELETE_FILTER_CHECKBOX = "DELETE_FILTER_CHECKBOX";
export const SET_UNIVERSITIES = "SET_UNIVERSITIES";
export const SET_PAGINATION = "SET_PAGINATION";
export const SELECT_COURSE = "courses/SELECT_COURSE";
export const SET_COURSES_LOADING = "courses/SET_COURSES_LOADING";
export const SET_SEARCH_BAR_VALUE = "courses/SET_SEARCH_BAR_VALUE";
export const SET_SEARCH_BAR_PAGINATION = "courses/SET_SEARCH_BAR_PAGINATION";
export const SET_SEARCH_BAR_QUERY = "courses/SET_SEARCH_BAR_QUERY";
export const SET_SEARCH_BAR_BY = "courses/SET_SEARCH_BAR_BY";
export const SET_SEARCH_BAR_LOADING = "courses/SET_SEARCH_BAR_LOADING";
export const SET_FOUND_COURSES = "courses/SET_FOUND_COURSES";
export const SET_FOUND_COURSES_PAGE = "courses/SET_FOUND_COURSES_PAGE";
export const SET_FAVORITE_SEARCHBAR_QUERY = "courses/SET_FAVORITE_SEARCHBAR_QUERY";
export const SET_FAVORITE_FILTERED_COURSES = "courses/SET_FAVORITE_FILTERED_COURSES";
export const SET_RECOMMENDED_FILTERED_COURSES = "courses/SET_RECOMMENDED_FILTERED_COURSES";
export const SET_RECOMMENDED_SEARCHBAR_QUERY = "courses/SET_RECOMMENDED_SEARCHBAR_QUERY";
export const SET_FAVORITE_SEARCHBAR_NUMBEROFPAGES = "courses/SET_FAVORITE_SEARCHBAR_NUMBEROFPAGES";
export const SET_FILTER_BY_DATE = "courses/SET_FILTER_BY_DATE";
export const SET_FILTER_BY_START_DATE = "courses/SET_FILTER_BY_START_DATE";
export const SET_COURSES_COUNT = "courses/SET_COURSES_COUNT";
export const CHANGE_PAGE = "courses/CHANGE_PAGE";
export const RESET_REGISTER_DATE = "courses/RESET_REGISTER_DATE";
export const RESET_START_DATE = "courses/RESET_START_DATE";
export const SET_RECURRING_DATE = "courses/SET_RECURRING_DATE";
export const RESET_RECURRING_DATE = "courses/RESET_RECURRING_DATE";
export const SET_SUBJECT_AREA_TAGS = "courses/SET_SUBJECT_AREA_TAGS";
export const ADD_SUBJECT_AREA_TAGS_FILTER = "courses/ADD_SUBJECT_AREA_TAGS_FILTER";
export const DELETE_SUBJECT_AREA_TAGS_FILTER = "courses/DELETE_SUBJECT_AREA_TAGS_FILTER";
export const RESET_ALL_FILTERS = "courses/RESET_ALL_FILTERS";
export const SET_COURSE_TYPE_FROM_INDEX = "courses/SET_COURSE_TYPE_FROM_INDEX";
export const RESET_STARTED_SAVING = "courses/RESET_STARTED_SAVING";
export const REMOVE_REDIRECT_FROM_INDEX = "courses/REMOVE_REDIRECT_FROM_INDEX";


