import React, { createContext, useReducer } from 'react';
import {
  initialApplicationState,
  initialAuthState,
  initialStateCourses,
  applymentsInitialState,
  essentialDataInitialState,
  signupInitialState
} from './initialState';

const store = createContext({});
const { Provider } = store;

import {
  applicationReducer,
  authReducer,
  coursesReducer,
  applymentsReducer,
  essentialDataReducer,
  signupReducer
} from './reducers';

const combineReducers = slices => (prevState, action) => {
  return Object.keys(slices).reduce(
    (nextState, nextProp) => ({
      ...nextState,
      [nextProp]: slices[nextProp](prevState[nextProp], action)
    }),
    prevState
  );
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    combineReducers({
      applicationReducer,
      authReducer,
      coursesReducer,
      applymentsReducer,
      essentialDataReducer,
      signupReducer
    }),
    {
      ...initialApplicationState,
      ...initialAuthState,
      ...initialStateCourses,
      ...applymentsInitialState,
      ...essentialDataInitialState,
      ...signupInitialState
    }
  );
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider } 