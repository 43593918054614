export const signupInitialState = {
  signupReducer: {
    progress: 0,
    activeStep: 0,
    formInfo: {
      company: {
        id: null, comp_name: ''
      },
      employee_families: '',
      firstname: '',
      paternal_lastname: '',
      maternal_lastname: '',
      user_phone: '',
      calling_country_code: '',
      username: '',
      email: '',
      password: '',
    },
    errorsInForm: {
      company: false,
      employee_families: false,
      firstname: false,
      paternal_lastname: false,
      maternal_lastname: false,
      user_phone: false,
      calling_country_code: false,
      username: false,
      email: false,
      password: false,
      terms: true,
    },
    isFormValid: {
      WELCOME_STEP: true,
      COMPANY_STEP: false,
      DATA_STEP: false,
      ACCOUNT_STEP: false,
    },
    companiesAtDropdown: [],
    userTypes: [],
    steps: [],
    activeStep: { id: 0, name: 'WELCOME_STEP' },
  }
};
